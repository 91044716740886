<template>
  <div class='addHouseForm-container'>
    <form-panel :submitUrl="submitUrl" :form="form" :hasHeader="false" :submitBefore="submitBefore" >
      <el-form-item label="项目信息" prop="communityId" :rules="[{ required: true, message: '请选择项目信息', trigger: 'change' }]" >
        <v-select2 class="communityId" v-model="form.communityId" placeholder="查询项目" v-bind="communityIdConfig" @onChange="getRoomInfo"></v-select2>
      </el-form-item>

      <el-form-item v-if="type === 1" label="房产信息" prop="roomId" :rules="[{ required: true, message: '请选择房产信息', trigger: 'change' }]">
        <v-select2 v-model="form.roomId" v-bind="roomIdOpsConfig" :subjoin="communityIdParams" :disabled="!form.communityId" @onChange="changeRoomInfo"></v-select2>
      </el-form-item>
      <el-form-item v-if="type === 0" label="房产信息" prop="roomId">
        <v-select2 v-model="form.roomId" placeholder="查询房产" v-bind="roomIdConfig" :subjoin="communityIdParams"></v-select2>
      </el-form-item>
      <div v-if="type === 1">
        <el-form-item label="组织信息" prop="orgId" :rules="[{ required: true, message: '请选择组织信息', trigger: 'change' }]">
          <v-select2 v-model="form.orgId" v-bind="orgIdOpsConfig" :disabled='!form.roomId' :subjoin="corgIdOpsConfigParams" @onChange="changeOrgTag" ></v-select2>
        </el-form-item>
      </div>

      <el-form-item label="房产类型" prop="houseType">
        <v-select disabled v-model="form.houseType" :options="houseTypeOps"></v-select>
      </el-form-item>
      <el-form-item label="所属组团" prop="group">
        <v-input v-model="form.group"></v-input>
      </el-form-item>
      <el-form-item label="房号编号" prop="roomNumber">
        <v-input v-model="form.roomNumber"></v-input>
      </el-form-item>
      <el-form-item label="完整编号" prop="roomFullNumber">
        <v-input v-model="form.roomFullNumber"></v-input>
      </el-form-item>
      <el-form-item label="租住时间">
        <v-datepicker :startTime.sync="form.rentStartDate" :endTime.sync="form.rentEndDate"/>
      </el-form-item>
      <el-form-item label="身份" prop="userType" :rules="[{ required: true, message: '请选择身份', trigger: 'change' }]">
        <v-select v-model="form.userType" :options="userTypeOps"></v-select>
      </el-form-item>
      <div v-if="showOwnerInfo">
        <el-form-item label="与户主关系" prop="userRelation">
          <v-select v-model="form.userRelation" :options="userRelationOps"></v-select>
        </el-form-item>
        <el-form-item label="是否付款联系人" prop="isPayment" :rules="[{ required: true, message: '请选择付款联系人', trigger: 'change' }]">
          <v-select v-model="form.isPayment" :options="isPaymentOps"></v-select>
        </el-form-item>
        <el-form-item label="是否短信联系人" prop="isMessage" :rules="[{ required: true, message: '请选择短信联系人', trigger: 'change' }]">
          <v-select v-model="form.isMessage" :options="isMessageOps"></v-select>
        </el-form-item>
        <el-form-item label="人房关系" prop="personRoomRelationship">
          <v-select v-model="form.personRoomRelationship" :options="personRoomList"></v-select>
        </el-form-item>
        <el-form-item label="租住关系" prop="lesseeRelationship">
          <v-select v-model="form.lesseeRelationship" :options="lesseeRelationshipList"></v-select>
        </el-form-item>
        <el-form-item label="房东姓名" prop="houseOwnerName">
          <v-input v-model="form.houseOwnerName"></v-input>
        </el-form-item>
        <el-form-item label="房东身份证号码" prop="houseOwnerCardnum">
          <v-input v-model="form.houseOwnerCardnum"></v-input>
        </el-form-item>
        <el-form-item label="房东手机号" prop="houseOwnerMobilenum">
          <v-input v-model="form.houseOwnerMobilenum"></v-input>
        </el-form-item>
        <el-form-item label="业主状态" prop="ownerState">
        <v-input v-model="form.ownerState"></v-input>
        </el-form-item>
        <el-form-item label="买房日期" prop="purchaseDate">
          <v-datepicker type="date" v-model="form.purchaseDate"/>
        </el-form-item>
        <el-form-item label="退租日期" prop="rentWithdrawalDate">
          <v-datepicker type="date" v-model="form.rentWithdrawalDate"/>
        </el-form-item>
        <el-form-item label="人员类型" prop="personType">
          <v-select v-model="form.personType" :options="personTypeList"></v-select>
        </el-form-item>
      </div>
      <el-form-item label="智能开门" prop="isOpenDoor">
        <v-select v-model="form.isOpenDoor" :options="isOpenDoorOps"></v-select>
      </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import * as allOwnerMgrFormUrl from './api'
import * as commonData from './map'
import { normalCommunityParams } from 'common/select2Params'
export default {
  name: 'AddHouseForm',
  components: {},
  props:['query'],
  data () {
    return {
      personRoomList: [{
        text: '请选择',
        value: undefined
      }],
      lesseeRelationshipList: [{
        text: '请选择',
        value: undefined
      }],
      personTypeList: [{
        text: '请选择',
        value: undefined
      }],
      form: {
        communityId: undefined,
        roomId: undefined,
        houseType: undefined,
        userType: undefined,
        userRelation: undefined,
        isPayment: undefined,
        isMessage: undefined,
        isOpenDoor: undefined,
        orgId: undefined,
        rentStartDate: '',
        rentEndDate: '',
        personRoomRelationship: undefined,
        lesseeRelationship: undefined,
        houseOwnerName: '',
        houseOwnerCardnum: '',
        houseOwnerMobilenum: '',
        personType: undefined,
        group: '',
        roomNumber: '',
        roomFullNumber: '',
        ownerState: '',
        rentWithdrawalDate: '',
        purchaseDate: ''
      },
      type: '',
      orgId: undefined,
      communityId: undefined,
      orgIdOps: [],
      roomIdOps: [],
      communityIdConfig: normalCommunityParams,
      roomIdConfig: {
        searchUrl: allOwnerMgrFormUrl.select2RoomIdUrl,
        request: {
          text: 'address',
          value: 'id'
        }
      },
      orgIdOpsConfig:{
        searchUrl: allOwnerMgrFormUrl.getOrgInfoUrl,
        response: {
          text: 'orgName',
          value: 'id'
        }
      },
      roomIdOpsConfig:{
        searchUrl: allOwnerMgrFormUrl.getRoomInfoUrl,
        request: {
          text: 'address',
          value: 'id'
        }
      },
      houseTypeOps: commonData.houseTypeOps,
      userTypeOps: commonData.setUserTypeOps(2),
      userRelationOps: commonData.userRelationOps,
      orgTag: undefined,
      showOwnerInfo: true,
      isPaymentOps: commonData.isPaymentOps,
      isMessageOps: commonData.isMessageOps,
      isOpenDoorOps: commonData.isOpenDoorOps,
      submitUrl: allOwnerMgrFormUrl.createHouseInfoUrl
    }
  },
  computed: {
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
    queryReal(){
      return  this.subPageType == 'drawer' ? this.query : this.$route.query
    },
    communityIdParams () {
      return {
        communityId: this.form.communityId
      }
    },
    communityIdParams () {
      return {
        communityId: this.form.communityId
      }
    },
    corgIdOpsConfigParams(){
      return {
         communityId: this.form.communityId,
         houseId:this.form.roomId
        }
    },
  },
  created () {
    this.getNationData()
    this.type = Number(this.queryReal.type)
    this.$setBreadcrumb('新增房产信息')
  },
  methods: {
    // 获取民族一系列列表
    getNationData () {
      let that = this;
      this.$axios.get(allOwnerMgrFormUrl.getInitCodeWordList)
        .then(res => {
          res.data.personRoomList.forEach(function (item) {
            that.personRoomList.push({
              text: item,
              value: item
            })
          })
          res.data.lesseeRelationshipList.forEach(function (item) {
            that.lesseeRelationshipList.push({
              text: item,
              value: item
            })
          })
          res.data.personTypeList.forEach(function (item) {
            that.personTypeList.push({
              text: item,
              value: item
            })
          })
        })
    },
    // 获取组织信息
    getOrgInfo () {
      this.orgIdOps = []
      this.form.orgId = undefined
      if(this.form.communityId){
        this.communityId = this.form.communityId
        this.$axios.get(allOwnerMgrFormUrl.getOrgInfoUrl, {
          params: {
            communityId: this.communityId,
            houseId:this.form.roomId
          }
        })
          .then(res => {
            let returnStatus = String(res.status)
            if (returnStatus === '100') {
              let _this = this
              res.data.forEach(function (item) {
                _this.orgIdOps.push({
                  text: item.orgName,
                  value: item.id,
                  orgTag: item.orgTag
                })
              })
            }
          })
      }
    },
    // 获取房产信息
    getRoomInfo () {
      this.roomIdOps = []
      this.orgIdOps = []
      this.form.roomId = undefined
      this.form.orgId = undefined
      this.form.houseType = undefined
      this.form.userType = undefined
      const _this = this
      
      this.$axios.get(allOwnerMgrFormUrl.getRoomInfoUrl, {
        params: {
          communityId: this.form.communityId,
        }
      })
        .then(res => {
          let returnStatus = String(res.status)
          if (returnStatus === '100') {
          // let _this = this
            res.data.forEach(function (item) {
              _this.roomIdOps.push({
                text: item.name,
                value: item.id,
                houseType: item.houseType
              })
            })
          }
        })
    },
    changeOrgTag(e){
      let _this =this
      this.form.userType = undefined
      if(!!e){
        if ( e.orgTag === 1) {
          _this.showOwnerInfo = false
          _this.userTypeOps = commonData.businessUserTypeOps
        } else {
          _this.showOwnerInfo = true
          _this.userTypeOps = commonData.setUserTypeOps(2)
        }
      }
    },
    // 切换房产信息
    changeRoomInfo (e) {
      console.log(e)
      this.form.userType = undefined
      this.form.houseType = undefined
      if (!!e) {
        this.form.houseType = e.houseType
      }
    },
  
    submitBefore (data) {
      data.userId = this.queryReal.userId ? this.queryReal.userId : this.queryReal.addUserId
      return true
    }
  }
}
</script>
